export const bloodGlucoseClinicalIDs = [
  'glucose_before_breakfast',
  'glucose_after_breakfast',
  'glucose_before_lunch',
  'glucose_after_lunch',
  'glucose_before_dinner',
  'glucose_after_dinner',
]
export const bloodPressureClinicalIDs = [
  'systolic',
  'diastolic',
]
export const o2SaturationClinicalIDs = [
  'o2_saturation',
]
export const heartRateClinicalIDs = [
  'pulse',
]
export const weightClinicalIDs = [
  'weight',
]
export const painLocationClinicalIDs = [
    'abdomen_left', 'abdomen_right', 'ankle_left_back', 'ankle_left_front', 'ankle_right_back', 'ankle_right_front',
    'arm_left_back', 'arm_left_front', 'arm_right_back', 'arm_right_front', 'back_center', 'back_left', 'back_right',
    'breast_left', 'breast_right', 'buttock_left', 'buttock_right', 'calf_left', 'calf_right',
    'chest_left_upper', 'chest_left_lower', 'chest_right_upper', 'chest_right_lower', 'ear_left', 'ear_right',
    'elbow_left_back', 'elbow_left_front', 'elbow_right_back', 'elbow_right_front', 'eye_left', 'eye_right',
    'fingers_left', 'fingers_right', 'foot_left_back', 'foot_left_front', 'foot_right_back', 'foot_right_front',
    'forearm_left_back', 'forearm_left_front', 'forearm_right_back', 'forearm_right_front',
    'hamstring_left', 'hamstring_right', 'hand_left_back', 'hand_right_back', 'head_back',
    'head_front', 'heel_left', 'heel_right', 'knee_left_back', 'knee_left_front', 'knee_right_back', 'knee_right_front',
    'leg_left', 'leg_right', 'loin_center', 'loin_left', 'loin_right', 'mouth',
    'neck_center_back', 'neck_left_back', 'neck_left_front', 'neck_right_back', 'neck_right_front', 'nose',
    'palm_left', 'palm_right', 'pelvis_left', 'pelvis_right', 'pubis',
    'shoulder_left_back', 'shoulder_left_front', 'shoulder_right_back', 'shoulder_right_front',
    'thigh_left', 'thigh_right', 'wrist_left_back', 'wrist_left_front', 'wrist_right_back', 'wrist_right_front',
]
export const pathToClinicalIDs = {
  blood_glucose: bloodGlucoseClinicalIDs,
  blood_pressure: bloodPressureClinicalIDs,
  o2_saturation: o2SaturationClinicalIDs,
  heart_rate: heartRateClinicalIDs,
  weight: weightClinicalIDs,
}
export const brandSuccess = '#4dbd74'
export const brandWarning = '#FFC107'
export const brandDanger = '#f86c6b'
let darkMode = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ||
  window.navigator.userAgent.includes('AndroidDarkMode')
export const brandGray = darkMode ? '#171a1c' : '#e5e5e5'
export const brandDarkGray = darkMode ? '#505b62' : '#a6a6a6'
export const brandBlack = darkMode ? '#fff' : '#000'
export const colors = {
  green: brandSuccess,
  yellow: brandWarning,
  red: brandDanger,
}
export const pathToRanges = {
  blood_glucose: {
    regular: {
      min: 0,
      max: 300,
      stepSize: 100,
    },
    extended: {
      min: 0,
      max: 600,
      stepSize: 200,
    },
  },
  blood_pressure: {
    regular: {
      min: 50,
      max: 200,
      stepSize: 50,
    },
    extended: {
      min: 0,
      max: 300,
      stepSize: 100,
    },
  },
  o2_saturation: {
    regular: {
      min: 85,
      max: 100,
      stepSize: 5,
    },
    extended: {
      min: 70,
      max: 100,
      stepSize: 10,
    },
  },
  heart_rate: {
    regular: {
      min: 40,
      max: 160,
      stepSize: 40,
    },
    extended: {
      min: 0,
      max: 240,
      stepSize: 80,
    },
  },
  weight: {
    regular: {
      min: 100,
      max: 250,
      stepSize: 50,
    },
    extended: {
      min: 0,
      max: 600,
      stepSize: 200,
    },
  },
  pain_location: {
    regular: {
      min: 0,
      max: 10,
      stepSize: 1,
    },
    extended: {
      min: 0,
      max: 10,
      stepSize: 1,
    },
  },
}
