<template>
  <ion-modal :is-open="showModal" :backdropDismiss="false">
    <ion-page>
      <ion-header :translucent="true">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-back-button @click="dismissModal()" default-href='/pain'/>
          </ion-buttons>
          <ion-title class="ion-text-capitalize rtm-vital-form-title">
            {{ getLocationName(location) }} Survey
          </ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-text-start" :fullscreen="true">
        <ion-loading :is-open="dailyRTMVitalLoading" message="Loading..." :duration="20000"
                     cssClass="medium-loading-container"
                     @didDismiss="dailyRTMVitalLoading=false"/>
        <div v-if="!dailyRTMVitalLoading">
          <ion-list v-if="formStep.name === 'vitalValue'">
            <ion-list-header>
              1 - What would you rate your pain out of 10?
            </ion-list-header>
            <ion-item>
              <ion-range v-model="dailyRTMVital.value" min="0" max="10" step="1" color="primary" pin snaps ticks>
                <ion-icon size="small" slot="start" :ios="bonfireOutline" :md="bonfireOutline"/>
                <ion-icon size="large" slot="end" :ios="bonfireOutline" :md="bonfireOutline"/>
              </ion-range>
            </ion-item>
            <div class="navigationButtons">
              <ion-button color="dark" @click="nextFormStep" class="nextButton" fill="clear">
                <ion-icon slot="end" :ios="chevronForwardOutline" :md="chevronForwardOutline"/>
                <div class="log-out-text">
                  Next
                </div>
              </ion-button>
            </div>
          </ion-list>
          <ion-list v-if="formStep.name === 'interference'" class="has-items-secondary">
            <ion-radio-group v-model="dailyRTMVital.patient_pain_questionnaire.interference" @ionChange="nextFormStep">
              <ion-list-header>
                2 - Does pain interfere with your day-to-day activities?
              </ion-list-header>
              <ion-item>
                <ion-label>No pain today</ion-label>
                <ion-radio slot="start" value="No pain today"></ion-radio>
              </ion-item>
              <ion-item>
                <ion-label>Some pain. I able to carry out most daily activities</ion-label>
                <ion-radio slot="start" value="Some pain. I able to carry out most daily activities"></ion-radio>
              </ion-item>
              <ion-item>
                <ion-label>Pain has limited my ability to carry out most daily activities</ion-label>
                <ion-radio slot="start"
                           value="Pain has limited my ability to carry out most daily activities"></ion-radio>
              </ion-item>
              <ion-item>
                <ion-label>Unable to carry out any or most daily activities due to pain</ion-label>
                <ion-radio slot="start"
                           value="Unable to carry out any or most daily activities due to pain"></ion-radio>
              </ion-item>
            </ion-radio-group>
            <div class="navigationButtons">
              <ion-button color="dark" @click="previousFormStep" fill="clear">
                <ion-icon slot="start" :ios="chevronBackOutline" :md="chevronBackOutline"/>
                <div class="log-out-text">
                  Previous
                </div>
              </ion-button>
              <ion-button color="dark" @click="nextFormStep" class="nextButton" fill="clear">
                <ion-icon slot="end" :ios="chevronForwardOutline" :md="chevronForwardOutline"/>
                <div class="log-out-text">
                  Next
                </div>
              </ion-button>
            </div>
          </ion-list>
          <ion-list v-if="formStep.name === 'duration'" class="has-items-secondary">
            <ion-radio-group v-model="dailyRTMVital.patient_pain_questionnaire.duration" @ionChange="nextFormStep">
              <ion-list-header>
                3 - How often do you feel this pain?
              </ion-list-header>
              <ion-item>
                <ion-label>Never</ion-label>
                <ion-radio slot="start" value="Never"></ion-radio>
              </ion-item>
              <ion-item>
                <ion-label>Rarely</ion-label>
                <ion-radio slot="start" value="Rarely"></ion-radio>
              </ion-item>
              <ion-item>
                <ion-label>Sometimes</ion-label>
                <ion-radio slot="start" value="Sometimes"></ion-radio>
              </ion-item>
              <ion-item>
                <ion-label>Often</ion-label>
                <ion-radio slot="start" value="Often"></ion-radio>
              </ion-item>
              <ion-item>
                <ion-label>Always</ion-label>
                <ion-radio slot="start" value="Always"></ion-radio>
              </ion-item>
            </ion-radio-group>
            <div class="navigationButtons">
              <ion-button color="dark" @click="previousFormStep" fill="clear">
                <ion-icon slot="start" :ios="chevronBackOutline" :md="chevronBackOutline"/>
                <div class="log-out-text">
                  Previous
                </div>
              </ion-button>
              <ion-button color="dark" @click="nextFormStep" class="nextButton" fill="clear">
                <ion-icon slot="end" :ios="chevronForwardOutline" :md="chevronForwardOutline"/>
                <div class="log-out-text">
                  Next
                </div>
              </ion-button>
            </div>
          </ion-list>
          <ion-list v-if="formStep.name === 'radiates'" class="has-items-secondary">
            <ion-radio-group v-model="this.dailyRTMVital.patient_pain_questionnaire.radiates" @ionChange="updateRadiates">
              <ion-list-header>
                4 - Does pain radiate to other areas?
              </ion-list-header>
              <ion-row>
                <ion-col>
                  <ion-item>
                    <ion-label>Yes</ion-label>
                    <ion-radio slot="start" :value="true"></ion-radio>
                  </ion-item>
                </ion-col>
                <ion-col>
                  <ion-item>
                    <ion-label>No</ion-label>
                    <ion-radio slot="start" :value="false"></ion-radio>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ion-radio-group>
            <div v-if="this.dailyRTMVital.patient_pain_questionnaire.radiates">
              <ion-list-header>
                Where?
              </ion-list-header>
              <ion-select v-model="dailyRTMVital.patient_pain_questionnaire.radiates_to" @ionChange="nextFormStep"
                          :interface-options="{header: 'Where?'}" placeholder="Select One">
                <ion-select-option v-for="painLocationClinicalID in painLocationClinicalIDs"
                                   :key="painLocationClinicalID" :value="painLocationClinicalID">
                  {{ getLocationName(painLocationClinicalID) }}
                </ion-select-option>
              </ion-select>
            </div>
            <div class="navigationButtons">
              <ion-button color="dark" @click="previousFormStep" fill="clear">
                <ion-icon slot="start" :ios="chevronBackOutline" :md="chevronBackOutline"/>
                <div class="log-out-text">
                  Previous
                </div>
              </ion-button>
              <ion-button color="dark" @click="nextFormStep" class="nextButton" fill="clear">
                <ion-icon slot="end" :ios="chevronForwardOutline" :md="chevronForwardOutline"/>
                <div class="log-out-text">
                  Next
                </div>
              </ion-button>
            </div>
          </ion-list>
          <ion-list v-if="formStep.name === 'response'" class="has-items-secondary">
            <ion-list-header>
              5 - Does your pain respond to medication or activity?
            </ion-list-header>
            <ion-item v-for="painResponse in dailyRTMVital.patient_pain_questionnaire.response" :key="painResponse">
              <ion-label>{{ painResponse.value }}</ion-label>
              <ion-checkbox slot="end" @update:modelValue="painResponse.isChecked = $event"
                            :modelValue="painResponse.isChecked">
              </ion-checkbox>
            </ion-item>
            <div class="navigationButtons">
              <ion-button color="dark" @click="previousFormStep(true)" fill="clear">
                <ion-icon slot="start" :ios="chevronBackOutline" :md="chevronBackOutline"/>
                <div class="log-out-text">
                  Previous
                </div>
              </ion-button>
              <ion-button color="dark" @click="nextFormStep" class="nextButton" fill="clear">
                  <ion-icon slot="end" :ios="chevronForwardOutline" :md="chevronForwardOutline"/>
                  <div class="log-out-text">
                    Next
                  </div>
              </ion-button>
            </div>
          </ion-list>
          <ion-list v-if="formStep.name === 'swelling'" class="has-items-secondary">
            <ion-radio-group v-model="dailyRTMVital.patient_pain_questionnaire.swelling" @ionChange="nextFormStep">
              <ion-list-header>
                6 - Is there any swelling?
              </ion-list-header>
              <ion-row>
                <ion-col>
                  <ion-item>
                    <ion-label>Yes</ion-label>
                    <ion-radio slot="start" :value="true"></ion-radio>
                  </ion-item>
                </ion-col>
                <ion-col>
                  <ion-item>
                    <ion-label>No</ion-label>
                    <ion-radio slot="start" :value="false"></ion-radio>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ion-radio-group>
            <div class="navigationButtons">
              <ion-button color="dark" @click="previousFormStep" fill="clear">
                <ion-icon slot="start" :ios="chevronBackOutline" :md="chevronBackOutline"/>
                <div class="log-out-text">
                  Previous
                </div>
              </ion-button>
              <ion-button color="dark" @click="nextFormStep" class="nextButton" fill="clear">
                <ion-icon slot="end" :ios="chevronForwardOutline" :md="chevronForwardOutline"/>
                <div class="log-out-text">
                  Next
                </div>
              </ion-button>
            </div>
          </ion-list>
          <ion-list v-if="formStep.name === 'warmth'" class="has-items-secondary">
            <ion-radio-group v-model="dailyRTMVital.patient_pain_questionnaire.warmth" @ionChange="finishForm">
              <ion-list-header>
                7 - Is there any warmth?
              </ion-list-header>
              <ion-row>
                <ion-col>
                  <ion-item>
                    <ion-label>Yes</ion-label>
                    <ion-radio slot="start" :value="true"></ion-radio>
                  </ion-item>
                </ion-col>
                <ion-col>
                  <ion-item>
                    <ion-label>No</ion-label>
                    <ion-radio slot="start" :value="false"></ion-radio>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ion-radio-group>
            <div class="navigationButtons">
              <ion-button color="dark" @click="previousFormStep" fill="clear">
                <ion-icon slot="start" :ios="chevronBackOutline" :md="chevronBackOutline"/>
                <div class="log-out-text">
                  Previous
                </div>
              </ion-button>
              <ion-button color="dark" @click="finishForm" class="nextButton" fill="clear">
                <ion-icon slot="end" :ios="chevronForwardOutline" :md="chevronForwardOutline"/>
                <div class="log-out-text">
                  Finish
                </div>
              </ion-button>
            </div>
          </ion-list>
        </div>
      </ion-content>
    </ion-page>
  </ion-modal>
</template>

<script>
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonModal,
  IonPage,
  IonBackButton,
  IonIcon,
  IonRange,
  IonRow,
  IonCol,
  IonRadioGroup,
  IonRadio,
  IonLoading,
  IonListHeader,
  IonLabel,
  IonItem,
  IonButton,
  IonList,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
} from '@ionic/vue'
import {
  bonfireOutline,
  chevronBackOutline,
  chevronForwardOutline,
} from 'ionicons/icons'
import {defineComponent} from 'vue'
import {humanize} from "../shared/utils"
import axios from "axios"
import {getDateWithTimezone} from "../utils/date"
import {painLocationClinicalIDs} from "../utils/vitals"
import {mapGetters} from "vuex"
import dayjs from "dayjs";

const defaultDailyRTMVital = {
  id: undefined,
  value: "0",
  patient_pain_questionnaire: {
    id: undefined,
    interference: null,
    radiates: null,
    radiates_to: null,
    response: [
      {value: 'Gets better with meds', isChecked: null},
      {value: 'Gets worse with activity', isChecked: null},
    ],
    swelling: null,
    warmth: null,
  },
}

export default defineComponent({
  name: 'RTMVitalFormModal',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonModal,
    IonPage,
    IonBackButton,
    IonIcon,
    IonRange,
    IonRow,
    IonCol,
    IonRadioGroup,
    IonRadio,
    IonLoading,
    IonListHeader,
    IonLabel,
    IonItem,
    IonButton,
    IonList,
    IonSelect,
    IonSelectOption,
    IonCheckbox,
  },
  props: ['showModal', 'location'],
  emits: ['dismissModal'],
  data() {
    return {
      dailyRTMVital: {},
      dailyRTMVitalLoading: true,
      formSteps: [
        {
          name: 'vitalValue',
          fieldNames: ['value'],
          checks: [{check: this.isInDailyRTMVital, args: ['id', undefined]}, {
            check: this.isInDailyRTMVital,
            args: ['value', '0']
          }],
          next: 'interference',
          previous: null,
          parseBeforeSend: {}
        },
        {
          name: 'interference',
          fieldNames: ['interference'],
          checks: [{
            check: this.isInDailyRTMVital,
            args: ['patient_pain_questionnaire.id', undefined]
          }, {check: this.isInDailyRTMVital, args: ['patient_pain_questionnaire.interference', null]}],
          next: 'duration',
          previous: 'vitalValue',
          parseBeforeSend: {}
        },
        {
          name: 'duration',
          fieldNames: ['duration'],
          checks: [{check: this.isInDailyRTMVital, args: ['patient_pain_questionnaire.duration', null]}],
          next: 'radiates',
          previous: 'interference',
          parseBeforeSend: {}
        },
        {
          name: 'radiates',
          fieldNames: ['radiates', 'radiates_to'],
          checks: [{check: this.isInDailyRTMVital, args: ['patient_pain_questionnaire.radiates', null]}],
          next: 'response',
          previous: 'duration',
          parseBeforeSend: {}
        },
        {
          name: 'response',
          fieldNames: ['response'],
          checks: [{
            check: this.allAreInDailyRTMVital,
            args: ['patient_pain_questionnaire.response.0.isChecked,patient_pain_questionnaire.response.1.isChecked,patient_pain_questionnaire.swelling', null]
          }],
          next: 'swelling',
          previous: 'radiates',
          parseBeforeSend: {'response': this.painResponseToString}
        },
        {
          name: 'swelling',
          fieldNames: ['swelling'],
          checks: [{check: this.isInDailyRTMVital, args: ['patient_pain_questionnaire.swelling', null]}],
          next: 'warmth',
          previous: 'response',
          parseBeforeSend: {}
        },
        {
          name: 'warmth',
          fieldNames: ['warmth'],
          checks: [{check: this.isInDailyRTMVital, args: ['patient_pain_questionnaire.warmth', null]}],
          next: null,
          previous: 'swelling',
          parseBeforeSend: {}
        },
      ],
      formStep: null,
    }
  },
  setup() {
    return {
      bonfireOutline,
      chevronBackOutline,
      chevronForwardOutline,
      painLocationClinicalIDs
    }
  },
  methods: {
    dismissModal (showToast) {
      this.$emit('dismissModal', showToast)
    },
    getLocationName(location) {
      return location ? humanize(location.split('_').reverse().join(' ')) : ''
    },
    clinicalIDAvailable () {
      return this.vitalsAvailable && this.patientRTMVitals.data[this.location].data.length
    },
    getDailyRTMVital () {
      if (!this.clinicalIDAvailable(this.location)) {
        return
      }
      let vitals = this.patientRTMVitals.data[this.location].data
      let lastVital = vitals[vitals.length - 1]
      let now = dayjs()
      let vitalDate = dayjs(lastVital.date)
      return now.isSame(vitalDate, 'day') ? lastVital : null
    },
    setDailyRTMVital() {
      let dailyRTMVital = this.getDailyRTMVital()
      if (dailyRTMVital) {
        dailyRTMVital = JSON.parse(JSON.stringify(this.getDailyRTMVital()))
        let patientPainQuestionnaire = dailyRTMVital.patient_pain_questionnaire
        if (patientPainQuestionnaire) {
          let painResponses = patientPainQuestionnaire.response || ''
          patientPainQuestionnaire.response = this.dailyRTMVital.patient_pain_questionnaire.response.map((painResponse) => {
            if (painResponses.includes(painResponse.value)) { painResponse.isChecked = true }
            return painResponse
          })
        } else {
          patientPainQuestionnaire = this.dailyRTMVital.patient_pain_questionnaire
        }
        this.dailyRTMVital = {...dailyRTMVital, patient_pain_questionnaire: patientPainQuestionnaire}
      }
    },
    createRTMVital() {
      let data = {date: getDateWithTimezone(), clinical_id: this.location, value: this.dailyRTMVital.value}
      return axios({
        method: 'post',
        url: `/patients/${this.userProfile.role_data.id}/create_rtm_vital/`,
        data: data,
      }).then((response) => {
        this.dailyRTMVital.id = response.data.id
      })
    },
    updateRTMVital() {
      let data = {date: getDateWithTimezone(), value: this.dailyRTMVital.value}
      return axios({
        method: 'patch',
        url: `/rtm_vitals/${this.dailyRTMVital.id}/`,
        data: data,
      })
    },
    pushRTMVital() {
      if (this.dailyRTMVital.id) {
        return this.updateRTMVital()
      } else {
        return this.createRTMVital()
      }
    },
    createPatientPainQuestionnaire() {
      let data = {}
      for (let fieldName of this.formStep.fieldNames) {
        let value = this.dailyRTMVital.patient_pain_questionnaire[fieldName]
        let parseBeforeSendFunc = this.formStep.parseBeforeSend[fieldName]
        data[fieldName] = parseBeforeSendFunc ? parseBeforeSendFunc(value) : value
      }
      data.vital_id = this.dailyRTMVital.id
      return axios({
        method: 'post',
        url: `/patients/${this.userProfile.role_data.id}/create_patient_pain_questionnaire/`,
        data: data,
      }).then((response) => {
        this.dailyRTMVital.patient_pain_questionnaire.id = response.data.id
      })
    },
    updatePatientPainQuestionnaire() {
      let data = {}
      for (let fieldName of this.formStep.fieldNames) {
        let value = this.dailyRTMVital.patient_pain_questionnaire[fieldName]
        let parseBeforeSendFunc = this.formStep.parseBeforeSend[fieldName]
        data[fieldName] = parseBeforeSendFunc ? parseBeforeSendFunc(value) : value
      }
      return axios({
        method: 'patch',
        url: `/patient_pain_questionnaires/${this.dailyRTMVital.patient_pain_questionnaire.id}/`,
        data: data,
      })
    },
    pushPatientPainQuestionnaire() {
      if (this.dailyRTMVital.id) {
        if (this.dailyRTMVital.patient_pain_questionnaire.id) {
          return this.updatePatientPainQuestionnaire()
        } else {
          return this.createPatientPainQuestionnaire()
        }
      } else {
        return Promise.resolve()
      }
    },
    pushFormStep() {
      if (this.formStep.name === 'vitalValue') {
        return this.pushRTMVital()
      } else {
        return this.pushPatientPainQuestionnaire()
      }
    },
    finishForm() {
      this.pushFormStep().then(() => {
        this.dismissModal(true)
      })
    },
    nextFormStep() {
      this.dailyRTMVitalLoading = true
      this.pushFormStep().then(() => {
        let newFormStep = this.formSteps.find(formStep => formStep.name === this.formStep.next)
        this.setFormStep(newFormStep)
        this.dailyRTMVitalLoading = false
      })
    },
    previousFormStep(save) {
      this.dailyRTMVitalLoading = true
      let newFormStep = this.formSteps.find(formStep => formStep.name === this.formStep.previous)
      if (save) {
        this.pushFormStep().then(() => {
          this.setFormStep(newFormStep)
          this.dailyRTMVitalLoading = false
        })
      } else {
        this.setFormStep(newFormStep)
        this.dailyRTMVitalLoading = false
      }
    },
    setFormStep(formStep) {
      if (formStep) {
        this.formStep = formStep
      } else {
        this.formStep = this.formSteps.find((step) => step.checks.find(({check, args}) => check(...args))) ||
            this.formSteps[0]
      }
    },
    isInDailyRTMVital(fields, value) {
      let lastValue
      let fieldList = fields.split('.')
      for (let [i, field] of fieldList.entries()) {
        if (i === 0) {
          lastValue = this.dailyRTMVital[field]
        } else {
          lastValue = lastValue[field]
        }
      }
      return lastValue === value
    },
    allAreInDailyRTMVital(allFields, value) {
      for (let fields of allFields.split(',')) {
        if (!this.isInDailyRTMVital(fields, value)) {
          return false
        }
      }
      return true
    },
    painResponseToString(painResponses) {
      return painResponses.filter(({isChecked}) => isChecked).map(({value}) => {
        return value
      }).join(', ')
    },
    updateRadiates() {
      if (this.dailyRTMVital.patient_pain_questionnaire.radiates === false) {
        this.dailyRTMVital.patient_pain_questionnaire.radiates_to = null
        this.nextFormStep()
      }
    },
    resetContainer () {
      this.formStep = this.formSteps[0]
      this.dailyRTMVital = JSON.parse(JSON.stringify(defaultDailyRTMVital))
    },
    setupModal () {
      this.dailyRTMVitalLoading = true
      this.resetContainer()
      this.setDailyRTMVital()
      this.setFormStep()
      this.dailyRTMVitalLoading = false
    },
  },
  computed: {
    ...mapGetters([
      'userProfile',
      'patientRTMVitals',
    ]),
    vitalsAvailable () {
      let vitals = this.patientRTMVitals
      return !this.loading && vitals.hasLoadedOnce && Object.values(vitals.data).some(({data}) => data.length)
    },
  },
  watch: {
    showModal (to) {
      if (!to) {return}
      this.setupModal()
    },
  },
})
</script>

<style scoped>
.rtm-vital-form-title {
  font-size: 1rem;
  text-align: left;
}
ion-item, ion-select {
  margin: 20px;
  --border-style: none;
}
.has-items-secondary ion-item::part(native) {
  background-color: var(--ion-color-secondary-tint);
  color: var(--ion-color-secondary-contrast);
}
ion-label {
  white-space: normal !important;
}
ion-list-header {
  font-size: 1rem;
  padding-right: 20px;
  padding-top: 20px;
}
ion-range::part(pin) {
  font-size: 0.9rem;
  zoom: 1.2;
  padding: 0.2rem 0 0 0;
  transform: translate3d(0, -25px, 0) scale(1) !important;
}
.ios ion-range::part(pin) {
  font-size: 0.9rem;
  zoom: 1.2;
  transform: translate3d(0, 7px, 0) scale(1) !important;
}
.md ion-range::part(pin) {
  font-size: 0.9rem;
  zoom: 1.2;
  padding: 0.2rem 0 0 0;
  transform: translate3d(0, -25px, 0) scale(1) !important;
}
.nextButton {
  float: right;
}
.navigationButtons {
  margin-top: 30px;
}
</style>
