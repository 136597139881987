import dayjs from "dayjs";

const getDateWithTimezone = () => {
    return dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
}
const getDayDifference = (timeWindow) => {
    switch (timeWindow) {
        case 'Week':
            return 7
        case 'Month':
            return 31
        case 'Day':
            return 1
        default:
            return 366
    }
}

export {
    getDateWithTimezone,
    getDayDifference,
}
